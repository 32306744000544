<template>
    <div style="text-align: center; background-color: white">
      <van-image :src="require('@/assets/common/error_pay.png')" width="30%" height="20%" />
      <div id="close">支付失败请点击右上方返回游戏重新下单</div>
    </div>
  </template>
    
  <script>
  export default {
    name: "PaySuccess",
    data() {
      return {
        show: true,
        payType: 0,//0为空中云汇支付，1为stripe支付
      }
  
    },
    created() {
        
    },
    methods: {
    }
  }
  </script>
    
  <style scoped>
  #close {
    font-size: 16px;
    text-align: center;
  }
  </style>
    